<template>
  <master-layout
    smallTitle="nuova"
    bigTitle="Rilevazione"
    titleStyle="horizontal"
  >
    <div class="relative">
      <div class="grid-center mb-4">
        <label
          v-if="loading"
          style="
            width: 100%;
            min-width: 300px;
            min-height: 150px;
            border-radius: 10px;
            display: grid;
            place-content: center;
            border: 1px solid goldenrod;
            color: goldenrod;
            font-weight: bold;
            font-size: 1.2rem;
            margin-top: 30px;
            padding: 1.2rem;
          "
          >Caricamento delle immagini in corso...</label
        >
        <button
          v-if="!loading"
          @click="triggerFileUploader"
          class="px-4 py-3 button-primary"
        >
          {{
            rilevazione.elementiMultimediali.length > 0
              ? "Tocca per caricare altre immagini"
              : "Tocca per caricare immagini"
          }}
        </button>

        <div
          v-if="!loading"
          style="
            width: 100%;
            min-width: 300px;
            height: 150px;
            display: grid;
            place-content: center;
            border-radius: 10px;

            margin-top: 30px;
          "
          :style="{
            border: 1 + 'px dashed ' + (isDragging ? '#011307' : '#110F03'),
            'background-color': isDragging ? '#D8FDE5' : '#FCF9ED',
          }"
          @dragover.prevent.stop="onDragOver($event)"
          @dragleave.prevent.stop="onDragLeave($event)"
          @drop.prevent="onDrop($event)"
          @paste="handlePaste($event)"
        >
          <span>O caricale trascinandole qui</span>
        </div>

        <input
          style="display: none"
          id="images-uploader"
          type="file"
          name="filefield"
          multiple
          @change="handleFileInput"
        />
      </div>
      <span v-if="rilevazione.elementiMultimediali.length > 0"
        >Hai caricato delle immagini, scrolla in fondo alla pagina per vederne
        la miniatura</span
      >

      <div class="ion-margin-bottom ion-padding-bottom">
        <ion-row class="ion-margin-top ion-padding-top">
          <ion-col>
            <ion-item>
              <ion-textarea
                :autoGrow="true"
                :rows="2"
                placeholder="Descrizione"
                v-model="rilevazione.descrizione"
              ></ion-textarea>
            </ion-item>
            <ion-item class="ion-margin-top">
              <ion-input
                :placeholder="loadingLuogoInizio ? 'Caricamento...' : 'Luogo'"
                inputmode="text"
                @ionFocus="setFocusTrue"
                @ionBlur="setFocusFalse"
                v-model="luogoInizio"
                required
              ></ion-input>
            </ion-item>
            <ion-item class="ion-margin-top ion-margin-bottom">
         <pre>{{ {minDate, maxDate} }}</pre>
              <ion-datetime
                :min="minDate"
                :max="maxDate"
                :value="minDate"
                @ionChange="handleDateTimeChange($event.detail.value)"
              ></ion-datetime>
            </ion-item>
          </ion-col>
        </ion-row>
      </div>

      <div
        class="ion-margin-bottom ion-padding background-white flex justify-content-start align-items-center"
      >
        <span>Seleziona gli indagati:</span>

        <ion-select
          v-model="rilevazione.indagati"
          :multiple="true"
          :interface-options="{
            header: 'Seleziona gli indagati',
          }"
          class="ion-margin-top ion-margin-bottom"
        >
          <ion-select-option
            v-for="indagato of ordineDiServizio.indagati"
            :key="indagato"
            :value="indagato"
            >{{ indagato }}</ion-select-option
          >
        </ion-select>
      </div>

      <div class="galleria pb-6">
        <div
          @click="showModal(index)"
          class="gal-image"
          v-for="(image, index) in rilevazione.elementiMultimediali"
          :key="index"
        >
          <img :src="image.urlElemento" @error="handleImgError" />
          <ion-icon
            @click="deleteImage(image)"
            :icon="trashOutline"
            class="delete-badge"
          ></ion-icon>
        </div>
      </div>

      <button
        :disabled="loading || disabled"
        @click="salvaRilevazione"
        class="fixed bottom center button-bottom-axerta-secondary w-75 text-16"
      >
        Salva
      </button>
    </div>
    <div id="bottom-component" class="bottom-component">
      <div v-if="tastieraFocus"></div>
    </div>
  </master-layout>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import MasterLayout from "../../components/MasterLayout.vue";
import { toastController } from "@ionic/vue";
import ApiService from "../../common/service.api";
import { trashOutline } from "ionicons/icons";
import { useAxertaStore } from "@/stores/useAxertaStore";
import axios from "axios";
import {
  IonAvatar,
  IonLabel,
  IonSelectOption,
  IonRow,
  IonCol,
  IonCard,
  IonList,
  IonItem,
  IonInput,
  IonSelect,
  IonTextarea,
  IonIcon,
  IonButton,
  IonFab,
  IonFabButton,
  modalController,
  IonDatetime,
  //   IonRouterOutlet,
  loadingController,
  IonLoading,
} from "@ionic/vue";

const route = useRoute();
const router = useRouter();

const AXERTA_STORE = useAxertaStore();

const ordineDiServizio = ref(AXERTA_STORE.ordineDiServizio);

const tastieraFocus = ref(false);
const isDragging = ref(false);

const luogoInizio = ref("");
const loadingLuogoInizio = ref(false);

onMounted(async () => {
  // get rilevazione from localhost
  // getRilevazioneFromLoocalHost();
  luogoInizio.value = "";
  await getUserLocation();
  handleDateTimeChange(minDate.value);
  rilevazione.value.indagati ? null : (rilevazione.value.indagati = []);
});

async function getUserLocation() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        await getLocationFrom(
          position.coords.latitude,
          position.coords.longitude
        );
      },
      (error) => {
        console.log(
          "🚀 ~ file: ProveEmmanuele.vue:29 ~ locatorButtonPressed ~ error:",
          error.message
        );
      }
    );
  } else {
    console.warn("Whappy ERR: Il tuo browser non supporta la GeoLocation API");
  }
}

async function getLocationFrom(lat, long) {
  loadingLuogoInizio.value = true;
  axios
    .get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        lat +
        "," +
        long +
        "&key=" +
        process.env.VUE_APP_GOOGLE_MAPS_API
    )
    .then((res) => {
      if (res.data.error_message) {
        console.log(
          "🚀 ~ file: ProveEmmanuele.vue:41 ~ getLocationFrom ~ res.data.error_message:",
          res.data.error_message
        );
      } else {
        luogoInizio.value = res.data.results[0].formatted_address;
        rilevazione.value.luogo = res.data.results[0].formatted_address;
      }
    })
    .catch((err) => {
      console.log(
        "🚀 ~ file: ProveEmmanuele.vue:46 ~ getLocationFrom ~ err:",
        err
      );
    })
    .finally(() => {
      loadingLuogoInizio.value = false;
    });
}

function deleteImage(image) {
  const index = rilevazione.value.elementiMultimediali.indexOf(image);
  rilevazione.value.elementiMultimediali.splice(index, 1);
}

function setFocusTrue() {
  tastieraFocus.value = true;
  scrollDown();
}
function setFocusFalse() {
  tastieraFocus.value = false;
}
function scrollDown() {
  document
    .querySelector("#bottom-component")
    .scrollIntoView({ behavior: "smooth" });
}

function onDragOver(event) {
  event.preventDefault();
  isDragging.value = true;
}
function onDragLeave(event) {
  event.preventDefault();
  isDragging.value = false;
}
function onDrop(event) {
  event.preventDefault();
  isDragging.value = false;
  handleFileInput({ target: { files: event.dataTransfer.files } });
}

function handlePaste(event) {
  console.log(event);
  // handleFileInput({ target: { files: event.clipboardData.files } });
}

const disabled = computed(() => {
  if (
    rilevazione.value.luogo.length > 0 &&
    rilevazione.value.descrizione.length > 0 &&
    rilevazione.value.elementiMultimediali.length > 0 &&
    rilevazione.value.dataOra.length > 0
  ) {
    return false;
  }
  return true;
});

const rilevazione = ref({
  descrizione: "",
  elementiMultimediali: [],
  luogo: "",
  dataOra: "",
  stato: "3",
});

function handleImgError() {
  return "https://placehold.co/300x300?text=MartechRevolution";
}

const minDate = computed(() => {
  return route.params.inizioIntervento.slice(0, 19)
});

const maxDate = computed(() => {
  if (route.params.fineIntervento == "in-corso") {
    let date = new Date();
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    let hour = date.getHours().toString().padStart(2, "0");
    let minute = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hour}:${minute}`;
    // return undefined
  } else {
    return new Date(route.params.fineIntervento).toISOString().slice(0, 19);
  }
});

const modalVisible = ref(false);
const currentImageIndex = ref("");

function handleDateTimeChange(event) {
  console.log("handle datetime change", event);

  let ora = event;

  rilevazione.value.dataOra = ora;

  console.log("handle datetime change", rilevazione.value);
}

const loading = ref(false);

function handleFileInput(event) {
  loading.value = true;
  const promises = [];

  for (let i = 0; i < event.target.files.length; i++) {
    const formData = new FormData();
    formData.append("file", event.target.files[i]);
    promises.push(
      ApiService.post("Upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
    );
  }

  Promise.all(promises)
    .then((values) => {
      values.forEach((value) => {
        rilevazione.value.elementiMultimediali.push({
          urlElemento: "https://api-axerta.whappy.it/Drive/" + value.data,
        });
      });
    })
    .finally(() => {
      loading.value = false;
      presentToast("File caricato");
    });
}






function salvaRilevazione() {
  loading.value = true;
  disabled.value = true;
  if (!rilevazione.value.indagati) rilevazione.value.indagati = [];
  ApiService.post(
    "DossierManager/AddRilevazioneDettaglio/" + route.params.idIntervento,
    rilevazione.value
  )
    .then((res) => {
      console.log(" success salva rilevazione ", res);
    })
    .finally(() => {
      rilevazione.value = {
        descrizione: "",
        elementiMultimediali: [],
        luogo: "",
        dataOra: "",
      };
      loading.value = false;
      disabled.value = false;
      router.push(
        {
          name: "ElencoRilevazioni",
          params: {
            idOrdine: route.params.idOrdine,
            idIntervento: route.params.idIntervento,
            inizioIntervento: route.params.inizioIntervento,
            fineIntervento: route.params.fineIntervento
              ? route.params.fineIntervento
              : "in-corso",
          },
        }

        // "/axerta/elenco-rilevazioni/" +
        //   route.params.idOrdine +
        //   "/" +
        //   route.params.idIntervento
      );
    });
}

async function presentToast(message) {
  const toast = await toastController.create({
    message: message,
    duration: 1500,
    position: "bottom",
    color: "success",
  });

  await toast.present();
}

function triggerFileUploader() {
  document.getElementById("images-uploader").click();
}
</script>

<style lang="scss" scoped>
.hello {
  position: relative;
}

.icon {
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
}

.galleria {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 10px;
}

.gal-image {
  position: relative;
  max-width: 30%;
  min-width: 100px;

  img {
    width: 100%;
    height: auto;
    border: 3px solid gray;
    border-radius: 5px;
  }

  .delete-badge {
    position: absolute;
    background-color: maroon;
    color: white;
    padding: 0.6rem;
    border-radius: 50%;
    top: -10px;
    right: -10px;
  }
}

.active {
  img {
    border: 4px solid rgb(85, 194, 85);
  }
}

.relative {
  position: relative;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 900000;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1rem;
  background-color: rgb(255, 255, 255);
  // border: 2px solid gray;
  border-radius: 10px;
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2);

  .header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .image-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    padding: 1rem 0;
  }

  .current-image {
    display: grid;
    place-content: center;
  }
}

input[type="file"] {
  border: 1px solid rgb(165, 165, 165);
  border-radius: 7px;
  padding: 1rem 2rem;
}

// .custom-file-upload {
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   margin-bottom: 3rem;
//   border-radius: 5px;
//   padding: 0.5rem 1rem;
//   cursor: pointer;
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06),
//     0px 2px 6px rgba(0, 0, 0, 0.12);
// }

.bottom-component {
  width: 100%;
  padding-top: 50vh;
}
</style>
